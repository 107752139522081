const MassageNav = ({ fill }) => {
	return (
		<>
		<svg width="183" height="174" viewBox="0 0 65 62" fill={fill} xmlns="http://www.w3.org/2000/svg">
		<path d="M39.7932 11.855C41.7461 8.48055 41.1014 4.4618 38.3531 2.87882C35.6048 1.29585 31.7937 2.7481 29.8408 6.12251C27.8879 9.49692 28.5326 13.5157 31.2809 15.0986C34.0292 16.6816 37.8402 15.2294 39.7932 11.855Z" fill=""/>
		<path d="M58.0716 56.8907C61.8994 56.1145 64.4899 52.9701 63.8578 49.8675C63.2256 46.7649 59.6101 44.8789 55.7823 45.6551C51.9546 46.4313 49.364 49.5757 49.9962 52.6783C50.6284 55.781 54.2439 57.6669 58.0716 56.8907Z" fill=""/>
		<path d="M40.412 40.324L39.97 40.2358C38.7324 39.9712 37.6715 39.3538 36.6991 38.6483C34.489 36.7963 31.6601 33.7096 30.1573 29.3L29.8921 28.1535C29.4501 26.9188 29.1849 25.596 29.0965 24.0967C29.0081 22.5092 29.1849 21.01 29.5385 19.6871C29.7153 19.0698 29.5385 18.3642 29.0965 17.8351L27.7704 16.5122C27.1516 15.8949 26.2676 15.8067 25.5603 16.1595C23.7923 17.1296 22.0242 18.8052 20.7866 21.01C18.8417 24.3613 18.4881 27.8889 19.4606 30.2701C20.0794 31.6812 20.2562 33.3568 19.8142 34.8561L19.7258 35.1206C19.0185 37.5018 16.5433 42.2642 15.1288 43.587C14.068 44.5571 13.8912 46.4974 15.1288 47.1147C16.7201 47.9084 20.6098 47.8202 21.9358 47.2029C23.9691 46.321 23.8807 45.6154 23.9691 44.5571C24.1459 41.5586 24.6763 39.5302 25.1183 38.031C25.2951 37.5018 25.4719 36.8845 25.5603 36.2671C25.6488 35.8262 26.0908 34.5033 26.0908 34.5033C26.5328 34.1505 27.1516 34.2387 27.5936 34.6797C28.2124 35.297 29.1849 36.3553 30.3341 37.5018C32.8978 39.883 35.9035 41.735 39.1744 42.8815C39.97 43.2343 40.854 42.8815 41.1192 41.9996C41.3844 41.3822 41.0308 40.6767 40.412 40.324Z" fill="="/>
		<path d="M58.2571 59.1895L55.163 58.3958L53.5717 58.043C52.6877 57.8667 51.8921 57.4257 51.1848 56.9847C48.9748 55.5737 48.0023 52.8397 48.8864 50.3704L49.24 49.4885C49.24 49.2239 49.0632 48.8711 48.798 48.6947C45.6155 46.6663 41.4605 45.4316 36.3332 45.4316C32.1782 45.4316 28.9957 45.7844 25.7248 47.1955C23.9568 47.901 20.5091 48.9593 20.4207 48.9593C16.531 49.753 11.492 47.9892 3.44736 48.0774C2.29812 48.0774 1.5025 48.5183 0.883678 49.2239C0.176456 50.1058 -0.177155 51.2523 0.0880533 52.3988C0.353262 53.4571 1.14889 54.4272 2.47493 54.8681C5.30381 55.4855 7.77909 55.1327 9.81235 55.1327C12.7296 55.0445 22.9844 55.6619 26.2553 56.3674C30.6754 57.3375 35.3607 57.5139 39.6925 56.3674C39.7809 56.3674 39.8693 56.3674 39.8693 56.2792L40.3113 56.191C40.3113 56.191 44.1126 58.5722 50.3892 59.6305L57.6382 61.5707C58.3455 61.7471 59.1411 61.3061 59.2295 60.5124C59.3179 59.8951 58.8759 59.2777 58.2571 59.1895Z" fill=""/>
		</svg>
		</>
	 );
}

export default MassageNav;
