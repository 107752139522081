import { useState } from 'react';
import styles from '../css/components/Voetreflexologie.module.css'
import DisciplinesHeader from "./DisciplinesHeader";

const Voetreflexologie = ({ control }) => {
	const [text2Active, setText2Active] = useState(false);

	const handleMeer = () => {
		setText2Active(true);
	}

	console.log(control);
	if(control === 'voetreflexologie') return (
		<article className={styles.main}>
		<article className={styles.content}>
			<header className={styles.header}>
			<h1 className={styles.title}>Voet reflexologie</h1>
			</header>
				<section className={styles.voet}>
					<img className={styles.voetImg} src='assets/img/voetreflexologie-voet.png' alt='voet-voetreflexologie' />
				</section>
				<section className={styles.part1}>
					<p className={`${styles.sub} ${styles.sub1}`}>Talrijke gebieden van de voet zijn op een bijzondere wijze verbonden met organen en lichaamsdelen.</p>
					<p className={`${styles.paragraph} ${styles.paragraph1}`}>
					Door dit reflexmatig verband, kunnen, via de voeten, onevenwichten hogerop in het lichaam hersteld worden.
					Voetrefelxologie kan zowel preventief en ondersteunend werken.
					</p>
				</section>
				<section className={styles.part2}>
					<div className={styles.part2Text}>
						<h3 className={styles.subTitle}>Behulpzaamheden</h3>
						<div className={`${styles.navPart} ${styles.navPart__mobile}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>immuunsysteem</p>
							<div className={styles.navLine}></div>
						</div>
						<p className={`${styles.paragraph} ${styles.paragraphPart2} ${styles.paragraph2}`}>
						Reflexologie biedt rust en ondersteunt ons immuunsysteem.
						Zij kan preventief en weldoend ingezet worden.
						Bij voorbeeld ook ter voorbereiding van en tijdens de zwangerschap of na de bevalling.
						Maar ook voor wie kampt met fertiliteitsproblemen of zelfs tijdens de menopauze.
						(link naar fertiliteit en zwangerschap)
						</p>
						<div className={`${styles.navPart} ${styles.navPart__mobile} ${styles.navPart__mid}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>lichamelijk</p>
							<div className={styles.navLine}></div>
						</div>
						<p className={`${styles.paragraph} ${styles.paragraphPart2} ${styles.paragraph3}`}>
						Voetreflexologie kan ook ingezet worden ter herstelling van onevenwichten en ondersteuning bij tal van lichamelijk van A tot Z.
						Van artrorse, artritis, aandachts, tot maag of spijsverteringsproblemen,
						spier- of gewrichtsklachten, verkoudheden tot zwaarlijvigheid.
						</p>
						<div className={`${styles.navPart} ${styles.navPart__mobile} ${styles.navPart__mid}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>Psychisch</p>
							<div className={styles.navLine}></div>
						</div>
						<p className={`${styles.paragraph} ${styles.paragraphPart2} ${styles.paragraph4}`}>
						Ook voor wie worstelt met psychsiche klachten of angsten, twijfels, zoals concentratiestoornissten,
						autisme, verslavingen, overmatig zweten...
						kan hulp vinden in psychozonereflexologie of transformatiereflexologie.
						</p>
						<div className={`${styles.navPart} ${styles.navPart__mobile} ${styles.navPart__mid}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>Voor wie</p>
							<div className={styles.navLine}></div>
						</div>
						<p className={`${styles.paragraph} ${styles.paragraphPart2} ${styles.paragraph5}`}>
						Op reflexologie staat geen leeftijd. De pasgeboren babies, kinderen, mannen, vrouwen, ouders en grootouders;
						iedereen heeft er baat bij en quasi op elk moment.
						</p>
					</div>
					<div className={styles.part2Nav}>
						<div className={`${styles.navPart}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>immuunsysteem</p>
						</div>
						<div className={styles.navLine}></div>
						<div className={`${styles.navPart}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>lichamelijk</p>
						</div>
						<div className={styles.navLine}></div>
						<div className={`${styles.navPart}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>Psychisch</p>
						</div>
						<div className={styles.navLine}></div>
						<div className={`${styles.navPart}`}>
							<div className={styles.navBall}></div>
							<p className={styles.navTag}>Voor wie</p>
						</div>
					</div>
				</section>
				{
				text2Active ?
				<section className={styles.part3}>
					<h2 className={styles.part3Title}>Fertiliteit & zwangerschap</h2>
					<div className={styles.part3Text}>
						<p className={styles.paragraph}>
							Bij de geboorte van een kind wordt het belang van een goede gezondheid van de baby door iedereen bevestigd.
							De gezondheid van de baby wordt niet enkele bepaald gedurende de zwangerschap zelf.
							Een goede gezondheid van moeder en vader zijn van belang.
						</p>
						<p className={styles.paragraph}>
							De vruchtbaarheid van man en vrouw kunnen soms verstoord zijn, waardoor de kinderwens maar niet vervuld raakt.
							Een gezonde levenswijze, beweging, nachtrust aangevuld met reflexologie waarbij de hormonenbalans in evenwicht
							wordt gebracht, biedt het gewenste antwoord op de kinderwens. Ook de baby heeft er deugd van. Niet alléén omwille
							van het gezond lichaam van beide ouders. Ook omdat de baby het zal ervaren als een bewust keuze van de ouders om
							de conceptie te laten gebeuren in de voor hem of haar beste omstandigheden: het kind weet dat het WELKOM is.
						</p>
						<p className={styles.paragraph}>
							Tijdens de zwangerschap ondergaat de vrouw op korte tijd tal van veranderingen. Hormonale, lichamelijke,
							zintuigelijke, emotionele veranderingen. De vrouw wordt moeder. Prenatale voetreflexologie laten moeder én
							baby zich gedragen voelen, waardoor zij beide voorbereid worden op de geboorte zelf.
						</p>
						<p className={styles.paragraph}>
							Postnatale reflexologie helpt de moeder op kracht te komen na de inspanningen geleverd bij de geboorte.
							Ook de baby heeft bij de geboorte heel wat lichamelijke en emotionele stress ondergaan. Het is daarom van
							belang dat de baby geborgenheid kan vinden bij een rustige moeder.
						</p>
						<p className={styles.paragraph}>
							Babies kunnen ook voetreflexologie krijgen, vanaf de derde maand na hun geboorte. De eerste drie maanden
							na de geboorte geven vader en moeder de nodige aandacht aan hun kind, wat de persoonlijkheid van het kind
							zal beInvloeden en bepalen. Nadien kan er steun van een derde worden gezocht.
						</p>
						<p className={styles.paragraph}>
							Wanneer voetreflexologie zwangerschap en geboorte ten goede kan komen, zal je je mogelijk afvragen of
							dit ook het geval is bij menopauze of verder nog bij premenstrueel syndroom, eierstokcysten, endomitriose,...
							Affirmatief!
						</p>
					</div>
				</section>
				:
				<button onClick={handleMeer} className={styles.meer}>
					<p className={styles.meerTag}>Lees meer over</p>
					<h2 className={`${styles.part3Title} ${styles.part3TitleClosed}`}>Fertiliteit & zwangerschap</h2>
				</button>
				}
			</article>
		</article>
	 );
	 return (
		 <></>
	 );
}

export default Voetreflexologie;
