import styles from '../css/pages/Aandeslag.module.css'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const Aandeslag = ({ path }) => {
	const el = useRef();
	const quote = useRef();
	const samen = useRef();
	const samenTitle = useRef();
	const title = useRef();
	const tl = useRef();
	const q = gsap.utils.selector(el);

	useEffect(() => {
		console.log(q())
			tl.current = gsap.timeline()
				.fromTo('body',
				{
				},
				{
					backgroundColor: '#273348',
					duration: .2,
					scrollTrigger: {
						id: 'background-aandeslag',
						trigger: quote.current,
						start: '-25% center',
						end: '130% center',
						toggleActions: 'play reverse play reverse',
					}
				})

				.fromTo('body',
				{
					color: 'black',
				},
				{
					color: 'white',
					duration: .5,
					scrollTrigger: {
						// id: 'background-aandeslag',
						trigger: quote.current,
						start: '-25% center',
						end: '130% center',
						toggleActions: 'play reverse play reverse',
					}
				});

				gsap.fromTo(q('.gsapTitle'),
				{
					color: '#273348',
				},
				{
					color: '#F2EEE9',
					duration: .5,
					scrollTrigger: {
						// id: 'background-aandeslag',
						trigger: quote.current,
						start: '-25% center',
						end: '130% center',
						toggleActions: 'play reverse play reverse',
					}
				});

				// gsap.fromTo(samenTitle.current,
				// 	{
				// 		color: '#273348',
				// 	},
				// 	{
				// 		color: '#F2EEE9',
				// 		duration: .5,
				// 		scrollTrigger: {
				// 			// id: 'background-aandeslag',
				// 			trigger: quote.current,
				// 			start: '-25% center',
				// 			end: '130% center',
				// 			toggleActions: 'play reverse play reverse',
				// 		}
				// 	});
	}, [])

	return (
		<main className={styles.main} ref={el}>
			<section className={styles.section}>
				<h1 className={styles.titleBG}>Aan de <br/> slag</h1>
				<div className={styles.part__intro}>
					<h2 className={`${styles.title} ${styles.gsapTitle}`} ref={title}>Aan de slag</h2>
					<p className={styles.chrono}>1</p>
					<p className={`${styles.sub} ${styles.sub1}`}>
						Ik sta een holistische benadering voor. Als je één of whole wil blijven of worden,
						Yin en Yang in evenwicht brengen, Ki laten stromen, de verantwoordelijkheid wenst op
						te nemen voor je eigen welzijn en evenwicht, sta ik u graag bij.
					</p>
				</div>
			</section>
			<section className={styles.part__disciplines}>
				<img className={styles.imgDisciplines} src='assets/img/disciplines.png' alt='disciplines' />
				<p className={styles.quote} ref={quote}>
					Ik ben je klankbord, zoek met jou de aandacht op die je lichaam en ziel vraagt,
					ondersteun je in het instandhouden van je welzijn of jouw genezing met o.a.
					<strong className={styles.strong}> voetrefelxologie, massage, kruidentherapie</strong> en <strong className={styles.strong}>yoga.</strong>
				</p>
				<div className={styles.disciplinesUitleg}>
					<p className={`${styles.paragraph} ${styles.paragraph3}`}>
						Ik help je als mens gezondheid te behouden of te vinden en help je de weg terug te vinden tot de natuur,
						je ‘eigen natuur’.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph4}`}>
						(Filosoof  ao Tze) De Nei Tjing medische klassieker van de gele keizer.
						De oude wijzen De wetten van Yin en Yang
					</p>

				</div>
			</section>
			<section className={styles.part__samen} ref={samen}>
				<h2 className={`${styles.subtitle} ${styles.gsapTitle}`} ref={samenTitle}>Samen te werk</h2>
				<div className={styles.samenContainer}>
					<p className={styles.chrono}>2</p>
					<p className={`${styles.sub} ${styles.sub2}`}>
						In een eerste sessie maken we kennis en bespreken we wat je tot deze ontmoeting brengt.
						We overlopen je voorgeschiedenis en je geeft de pathologieën aan waarvoor je in behandling bent of was.
					</p>
				</div>
				<div className={`${styles.samenContainer} ${styles.samenContainer2}`}>
					<p className={`${styles.paragraph} ${styles.paragraph5}`}>
						Samen bepalen we welke aanpak het best past en met welke regelmaat.
						Voorzie voor de eerste sessie anderhalf uur. Je wenst immers rustig je verhaal te kunnen doen,
						je tenen en voeten worden gelezen en mogelijk kan je al een eerste voetmassage krijgen.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph6}`}>
						Om je een idee te geven.
						Ben je gezond en wel, dan zal een zesmaandelijks bezoek volstaan.
						In het andere geval, zal een wekelijks bezoek gedurende de eerste maand nodig zijn, om nadien een tweewekelijkse of maandelijkse afspraak te maken.
					</p>
				</div>
				{/* <img src='' alt='samen' /> */}
			</section>
			<section className={styles.part__nav}>
				<p className={`${styles.paragraph} ${styles.paragraph7}`}>Alles van adres tot kostprijs, benodigdheden en praktische <br/> voorwaarden vindt u op de praktisch pagina</p>
				<Link to='/Praktisch' className={styles.banner}>
					<div className={styles.scrollContainer}>
						<p className={styles.navItem}>Praktisch</p>
						<div className={`${styles.paragraph} ${styles.navTag}`}>Navigeer</div>
						<p className={styles.navItem}>Praktisch</p>
						<div className={`${styles.paragraph} ${styles.navTag}`}>Navigeer</div>
						<p className={styles.navItem}>Praktisch</p>
						<div className={`${styles.paragraph} ${styles.navTag}`}>Navigeer</div>
						<p className={styles.navItem}>Praktisch</p>
						<div className={`${styles.paragraph} ${styles.navTag}`}>Navigeer</div>
						<p className={styles.navItem}>Praktisch</p>
						<div className={`${styles.paragraph} ${styles.navTag}`}>Navigeer</div>
						<p className={styles.navItem}>Praktisch</p>
						<div className={`${styles.paragraph} ${styles.navTag}`}>Navigeer</div>
					</div>
				</Link>
			</section>
		</main>
	 );
}

export default Aandeslag;
