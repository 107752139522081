const KruidenNav = ({ fill }) => {
	return (
		<>
			<svg width="125" height="185" viewBox="0 0 44 65" fill={fill} xmlns="http://www.w3.org/2000/svg">
			<path d="M21.9764 2.78951C21.9764 2.78951 23.3751 0.398501 20.278 0C9.488 0.896628 7.78958 9.86291 4.99219 10.8592C7.88949 12.4532 20.5777 18.8292 24.574 6.17677C24.8737 3.78576 23.6748 3.28764 21.9764 2.78951Z" fill=""/>
			<path d="M26.9693 14.0467C26.9693 14.0467 27.5687 10.4602 32.5641 13.2497C37.5594 16.0393 35.7611 22.5149 38.7583 24.4078C36.3605 24.2085 28.1682 30.2857 24.3717 18.4303C23.9721 17.2348 24.1719 15.9396 24.9711 15.043C25.5706 14.3456 26.2699 13.9471 26.9693 14.0467Z" fill=""/>
			<path d="M22.4791 47.7978C12.0887 47.7978 3.29693 45.4068 0.199814 42.0195C0.099907 42.9162 0 43.8128 0 44.809C0 55.7678 9.79088 64.7341 21.9795 64.7341C34.1682 64.7341 43.9591 55.7678 43.9591 44.809C43.9591 44.1117 43.9591 43.5139 43.8591 42.8165C40.1626 45.7057 31.9702 47.7978 22.4791 47.7978Z" fill=""/>
			<path d="M22.05 45.8372C34.1718 45.8372 43.9984 42.9141 43.9984 39.3083C43.9984 35.7024 34.1718 32.7793 22.05 32.7793C9.92821 32.7793 0.101563 35.7024 0.101563 39.3083C0.101563 42.9141 9.92821 45.8372 22.05 45.8372Z" fill=""/>
			<path d="M9.83594 10.279C9.83594 10.279 12.1975 9.86228 15.6703 8.19531C17.0595 7.52852 19.5599 6.52834 21.4798 3.88477" fill=""/>
			<path d="M9.83594 10.279C9.83594 10.279 12.1975 9.86228 15.6703 8.19531C17.0595 7.52852 19.5599 6.52834 21.4798 3.88477" stroke="#273348" stroke-miterlimit="10" stroke-linecap="round"/>
			<path d="M27.6117 15.5566C27.6117 15.5566 27.2331 22.3396 36.2248 23.3358Z" fill=""/>
			<path d="M27.6117 15.5566C27.6117 15.5566 27.2331 22.3396 36.2248 23.3358" stroke="#273348" strokeMiterlimit="10" strokeLinecap="round"/>
			</svg>
		</>
	 );
}

export default KruidenNav;
