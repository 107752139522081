import Home from './pages/Home';
import { Route, Routes, useLocation } from"react-router-dom";
import './css/App.css';
import Praktisch from './pages/Praktisch';
import Contact from './pages/Contact';
import Disciplines from './pages/Disciplines';
import Aandeslag from './pages/Aandeslag';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Introductie from './pages/Introductie';

function App() {
	const path = useLocation().pathname;
	const [navColor, setNavColor] = useState(['#ffffff', '#273348']);
	const [bgColor, setBgcolor] = useState('#273348');

	console.log(path)

	useEffect(() => {
		let pathTitle;
		if(path === '/') {
			pathTitle = 'home'
		} else {
			pathTitle = path.replace('/','')
		}

		document.title = `matheyo - ${pathTitle.toLocaleLowerCase()}`
		if(path === '/Aandeslag') {
			ScrollTrigger.getById('background-aandeslag').refresh()
		} else {
			ScrollTrigger.getAll().forEach((instance) => {
				instance.kill();
				document.querySelector('body').style = '';
			});
		}
	if(path === '/Contact' || path === '/Praktisch' || path === '/Aandeslag' || path === '/Introductie'){
		setBgcolor('#F2EEE9')
		setNavColor('#273348')
	} else {
		setBgcolor('#273348')
		setNavColor('#ffffff')
	}
	},[setBgcolor, setNavColor, path])

	if(path === '/') return (
		<>
		<Navigation color={navColor} />
		<div>
		<h1 style={{display: 'none'}}>matheyo</h1>
			<h2 style={{display: 'none'}}>yoga</h2>
				<h3 style={{display: 'none'}}>yoga lessen</h3>
				<h3 style={{display: 'none'}}>yoga in de buurt</h3>
				<h3 style={{display: 'none'}}>Yoga gent</h3>

				<h2 style={{display: 'none'}}>massage</h2>
				<h3 style={{display: 'none'}}>massage waregem</h3>
				<h3 style={{display: 'none'}}>massage in de buurt</h3>

				<h2 style={{display: 'none'}}>voetreflexologie</h2>
				<h3 style={{display: 'none'}}>voetreflexologie praktijk</h3>
				<h3 style={{display: 'none'}}>voetreflexologie zwangerschap</h3>
				<h3 style={{display: 'none'}}>voetreflexologie gent</h3>
				<h3 style={{display: 'none'}}>voetreflexologie massage</h3>
				<h3 style={{display: 'none'}}>voetreflexologie ervaringen</h3>

				<h2 style={{display: 'none'}}>kruidentherapie</h2>
				<h3 style={{display: 'none'}}>kruidentherapie gent</h3>
				<h3 style={{display: 'none'}}>hoe snel werken chinese kruiden</h3>
				<h3 style={{display: 'none'}}>chinese kruiden ervaring</h3>
			</div>
			<Routes>
			<Route index element={<Home bgColor={bgColor} />}></Route>
			<Route path='/Disciplines' element={<Disciplines onNavColor={color => setNavColor(color)} />} ></Route>
			<Route path='/Aandeslag' element={<Aandeslag path={path} />} ></Route>
			<Route path='/Introductie' element={<Introductie />} ></Route>
			<Route path='/Praktisch' element={<Praktisch />} ></Route>
			<Route path='/Contact' element={<Contact />} ></Route>
			</Routes>
		</>
  );
	if(path === '/Contact') return (
		<>
			<Navigation color={navColor} />
			<style>{`body {background-color:${bgColor}}`}</style>
				<Routes>
					<Route path='/Disciplines' element={<Disciplines />} ></Route>
					<Route path='/Aandeslag' element={<Aandeslag path={path} />} ></Route>
					<Route path='/Introductie' element={<Introductie />} ></Route>
					<Route path='/Praktisch' element={<Praktisch />} ></Route>
					<Route path='/Contact' element={<Contact />} ></Route>
				</Routes>
		</>
  );
	return (
		<>
			<Navigation color={navColor} />
			<style>{`body {background-color:${bgColor}}`}</style>
				<Routes>
					<Route path='/Disciplines' element={<Disciplines />} ></Route>
					<Route path='/Aandeslag' element={<Aandeslag path={path} />} ></Route>
					<Route path='/Introductie' element={<Introductie />} ></Route>
					<Route path='/Praktisch' element={<Praktisch />} ></Route>
					<Route path='/Contact' element={<Contact />} ></Route>
				</Routes>
			<Footer />
		</>
  );

}

export default App;
