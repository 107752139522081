const YogaNav = ({ fill }) => {
	return (
		<>
		<svg width="182" height="202" viewBox="0 0 64 71" fill={fill} xmlns="http://www.w3.org/2000/svg">
		<path d="M31.3126 20.1604C33.6137 18.7804 34.0839 15.328 32.3629 12.4493C30.642 9.5706 27.3815 8.35565 25.0805 9.73566C22.7794 11.1157 22.3092 14.568 24.0302 17.4467C25.7511 20.3255 29.0116 21.5404 31.3126 20.1604Z" fill=""/>
		<path d="M36.2917 6.42621C35.4057 4.85808 33.4601 5.86556 34.0196 7.528C35.7022 12.8136 37.3881 20.1616 36.2917 27.0394C32.2923 28.7392 20.3854 19.7872 15.8059 18.3332C14.01 17.763 13.1166 19.954 14.9493 21.0308C25.7662 27.4349 31.6337 30.8713 31.5088 39.0475C26.6299 50.3666 17.6139 58.4028 9.38637 60.3438C7.90083 60.664 8.26046 62.9559 9.80724 62.7541C19.8348 61.6816 37.5831 53.1741 41.8657 39.6859C45.7855 27.9633 40.5705 14.1199 36.2917 6.42621Z" fill=""/>
		<path d="M42.3149 41.4919C42.613 40.6913 43.4818 40.3257 44.2813 40.6236C48.621 42.2963 61.8386 51.0351 59.0791 65.1276C58.6198 66.9578 56.244 66.3615 56.5205 64.4855C56.797 62.6095 50.7511 50.8562 46.1821 49.7096C42.07 48.6777 40.3209 46.3414 42.3149 41.4919Z" fill=""/>
		</svg>
		</>
	 );
}

export default YogaNav;
