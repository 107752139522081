import styles from '../css/components/Yoga.module.css'

const Yoga = ({ control }) => {
if(control === 'yoga')	return (
		<article className={styles.main}>
			<section className={styles.content}>
			<header className={styles.header}>
				<h1 className={`${styles.title} ${styles.yogaTitle}`}>Yoga</h1>
			</header>
				<div className={styles.text__part1}>
					<p className={`${styles.sub} ${styles.sub1}`}>Yoga helpt je bewust te worden van je ademhaling en hoe je lichaam zich uitdrukt.</p>
					<p className={`${styles.paragraph} ${styles.paragraph1}`}>
					In goede gezondheid brengt Yoga rust. Kleine ongemakken vragen onze aandacht.
					Onevenwichten of geblokkeerde energie kan weer stromen door toepassing van gerichte oefeningen.
					Yoga en ademhaling hebben een meditatief effect.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph2}`}>
					Yoga geeft je de mogelijkheid verantwoordelijkheid te nemen en je zelfgenezend vermogen aan te spreken.
					Je hoeft helemaal geen yogi te zijn om dit te kunnen.
					Gaandeweg zal je lichaam souplesse winnen, maar het is niet de bedoeling dat we ons dubbel kunnen plooien.
					Ik kan dat overigens ook niet.
					</p>
				</div>
				<div className={styles.figure}>
					<img src='assets/img/yogafigure.svg' alt='yoga figure' />
				</div>
				<div className={styles.graphics}>
					<div className={styles.sun}>
						<p className={`${styles.yinyang} ${styles.yin}`}>yin</p>
						<img className={styles.graphic__sun} src="assets/img/yoga-sun.png" alt="yoga" />
					</div>
					<div className={styles.lines}>
						<img className={styles.lines__yin} src='assets/img/line-yinyang.svg' alt='' />
						<img className={styles.lines__universe} src='assets/img/line-universe.svg' alt='' />
					</div>
					<div className={styles.moon}>
						<p className={`${styles.yinyang} ${styles.yang}`}>yang</p>
						<img className={styles.graphic__moon} src="assets/img/yoga-moon.png" alt="yoga" />
					</div>
				</div>
				<div className={styles.text__part2}>
					<h2 className={styles.subTitle}>Te werk</h2>
					<p className={`${styles.paragraph} ${styles.paragraph3}`}>Ik zet je graag op weg met oefeningen.
						Met algemene deugddoende oefeningen of de evenwichtsherstellende meridiaanoefeningen van
						Massunaga en zonodig ook met oefeningen afgestemd op bepaalde pathologiëen of ziektebeelden.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph4}`}>Dit kan individueel.
							Of vind je het leuker in een kleine groep tot 5 personen?
							Spreek dan met vrienden af en we gaan op zoek naar kandidaten en een moment dat het past.
					</p>
				</div>
			</section>
		</article>
	 );
	 return (
		<></>
	);
}

export default Yoga;
