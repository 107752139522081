import { useState } from 'react';
import styles from '../css/components/Kruidentherapie.module.css'

const Kruidentherapie = ({ control }) => {
	const [text2Active, setText2Active] = useState(false);

	const handleBachBloesems = () => {
		setText2Active(true);
	}

	if(control === 'kruidentherapie')	return (
		<article className={styles.main}>
			<article className={styles.content}>
			<header className={styles.header}>
				<h1 className={styles.title}>Kruiden<br/>Therapie</h1>
			</header>
				<section className={styles.part1}>
					<div className={styles.text__part1}>
						<p className={`${styles.sub} ${styles.sub1}`}>Deze therapie is complementair aan en versterkt de effecten van refloxologie en massage.</p>
						<p className={`${styles.paragraph} ${styles.paragraph1}`}>
						 	Kruiden en oliën zijn een geschenk, ons aangboden door de natuur.
						 	Ze kunnen gebruikt worden in onze dagelijkse spijs of drank.
						 	Daarnaast kunnen ze heel specifiek ingezet worden om pijnen te verzachten en het
						 	helingsproces op gang te brengen.
						</p>
						<p className={`${styles.sub} ${styles.sub2}`}>We zetten kruiden in om het zelfgenezend vermogen ter ondersteunen.</p>
						<p className={`${styles.paragraph} ${styles.paragraph2}`}>
							Gaandeweg zal je een eigen kruiden-EHBO-kit bij je thuis hebben en zal
							je bewust inspelen met kruiden op de signalen waarop je lichaam je attent maakt.
						</p>
					</div>
				</section>
				{text2Active ?
				<section className={styles.part2}>
						<h3 className={styles.bach__title}>Bach <strong>Bloesems</strong></h3>
						<p className={`${styles.paragraph} ${styles.paragraph3}`}>
							Dr.  Bach (°1886-+ 1636) heeft tussen de twee
							wereldoorlogen homeopathische therapie vereenvoudigd en toegankelijker gemaakt.
							Dr. Bach was er sterk van overtuigd dat de gemoedstoestand van een
							persoon in grote mate verband houdt met de ontwikkleing van een ziekte.
						</p>
						<p className={`${styles.paragraph} ${styles.paragraph4}`}>
							Het unieke van de Bach Remedies, is dat zij niet werken op lichamelijke ziektesymptomen,
							doch deze wel doen verdwijnen door harmonisatie van karakter en geest.
							Dat disharmonieën in het karkater en stemmingen bepaalde ziektesymptomen kunnen veroorzaken wordt
							momenteel ook door de medische wetenschap erkend, die deze combinaties van symptome en stemmingen
							groepeert onder de naam psycho-somatische ziekten, waarbij een wisselwerking tussen de psyche en het
							lichaam verondersteld wordt. <strong>(*)</strong>
						</p>
					<div className={styles.part2__text2}>
						<p className={`${styles.paragraph} ${styles.paragraph5}`}>
							Bachbloesems worden aangereikt wanneer de gemoedstoestand duidelijk kan omschreven worden.
							We concentreren ons op de diepere oorzaak en niet de uiterlijke verschijnselen of symptomen van een ziekte.
						</p>
						<p className={`${styles.paragraph} ${styles.paragraph__star}`}>
							(*) Uit Genezing door bloemen, oorspronkelijke titel: ‘Heal Thyself’ van Dr. Bach
						</p>
					</div>
				</section>
				:
				<button onClick={handleBachBloesems} className={styles.meerBach}>
					<p className={styles.meerTag}>Lees meer over</p>
					<h3 className={`${styles.bach__title} ${styles.bach__titleClosed}`}>Bach <strong>Bloesems</strong></h3>
				</button>
			}
			</article>
			<article className={styles.part3}>
				<img className={`${styles.img__intro}`} src='assets/img/kruiden-image-intro.png' alt='intro-img' />
				<img className={text2Active ? `${styles.img__bach} ${styles.img__bachScoot}` : `${styles.img__bach}`} src='assets/img/kruiden-image-bach.png' alt='intro-img' />
			</article>
		</article>
	 );
	 return (
		<></>
	);
}

export default Kruidentherapie;
