import { Link } from 'react-router-dom';
import styles from '../css/components/Massage.module.css'
import DisciplinesHeader from './DisciplinesHeader';
// import massageImg from 'assets/img/massage-detail.svg';

const Massage = ({ control }) => {
	if(control === 'massage') return (
		<article>
			<article className={styles.content}>
			<header className={styles.header}>
			<h1 className={styles.title}>Massage</h1>
			</header>
				<div className={`${styles.textPart} ${styles.textPart1}`}>
					<p className={`${styles.sub} ${styles.sub1}`}>Massage geeft een rustgevend en relaxerend effect.</p>
					<p className={`${styles.paragraph} ${styles.paragraph1}`}>
						Door aanraking en de strijkbewegingen van de
						handen worden de doorbloeding van het lichaam, de lymfenstroom en het immuunsysteem gestimuleerd.
						Je krijgt ook aandacht. Van degene die masseert maar ook eigen aandacht terwijl je voelt: wat maken
						die handen los bij jou? Je aandacht raakt je ziel.
					</p>
				</div>
				<div className={`${styles.textPart} ${styles.textPart2}`}>
					<p className={`${styles.sub} ${styles.sub2}`}>Ik help je tot rust komen en maak spanningen los.</p>
					<p className={`${styles.paragraph} ${styles.paragraph2}`}>
						Met een relaxatiemassage of met een hotstonemassage.
						Voor wie toepasselijk, is je te kunnen bijstaan in je zwangerschap met zwangerschapsmassage en -refelxologie <b className={styles.link}>(link naar fertiliteit en  zwangerschap)</b>
					</p>
				</div>
				<section className={styles.img__content}>
					<img className={styles.main__img} src='assets/img/massage-img.png' alt='masserend hand - persoon op tafel' />
					<img className={styles.paint} src='assets/img/massage-paint.png' alt='paint' />
				</section>
			</article>
		</article>
	 );
	 return (
		<></>
	);
}

export default Massage;
