import styles from '../css/pages/Disciplines.module.css'
import Yoga from '../components/Yoga.js'
import Kruidentherapie from '../components/Kruidentherapie';
import Massage from '../components/Massage';
import Voetreflexologie from '../components/Voetreflexologie';
import { useState } from 'react';
import YogaNav from '../components/svg/YogaNav';
import VoetNav from '../components/svg/VoetNav';
import MassageNav from '../components/svg/MassageNav';
import KruidenNav from '../components/svg/KruidenNav';
const Disciplines = ( {onNavColor} ) => {
	// onNavColor('#273348');
	// const [bgColor, setBgcolor] = useState('#273348');
	// const [colorBack, setBack] = useState('#273348');

	const [nav, setNav] = useState('voetreflexologie');

	const handleClick = (e) => {
		setNav(e.currentTarget.id);
		console.log(nav);
	}

	return (
		<main className={styles.main}>
			<section className={styles.nav}>
				<h1 className={styles.title}>Disciplines</h1>
				<div className={styles.navLine}></div>
				<ul className={styles.menu}>
					<li onClick={handleClick} id='voetreflexologie' className={nav === 'voetreflexologie' ? `${styles.navItem} ${styles.navItem__active}` : styles.navItem}>
							<VoetNav fill={nav === 'voetreflexologie' ? 'white' : '#93A2BE'} stroke={'#273348'} />
							{/* <img src='assets/img/nav/voet-nav.svg' alt='voetreflexologie'/> */}
							<p className={`${styles.tag}`}>Voet-<br/>reflexologie</p>
						<button className={styles.navButton}>
						</button>
					</li>
					<li onClick={handleClick} id='kruidentherapie' className={nav === 'kruidentherapie' ? `${styles.navItem} ${styles.navItem__active}` : styles.navItem}>
							<KruidenNav fill={nav === 'kruidentherapie' ? 'white' : '#93A2BE'} />
							{/* <img src='assets/img/nav/kruiden-nav.svg' alt='kruidentherapie'/> */}
							<p className={`${styles.tag}`}>Kruiden<br/>therapie</p>
						<button className={styles.navButton}>
						</button>
					</li>
					<li onClick={handleClick} id='massage' className={nav === 'massage' ? `${styles.navItem} ${styles.navItem__active}` : styles.navItem}>
							<MassageNav fill={nav === 'massage' ? 'white' : '#93A2BE'} />
							{/* <img src='assets/img/nav/massage-nav.svg' alt='massage'/> */}
							<p className={`${styles.tag}`}>Massage</p>
						<button className={styles.navButton}>
						</button>
					</li>
					<li onClick={handleClick} id='yoga' className={nav === 'yoga' ? `${styles.navItem} ${styles.navItem__active}` : styles.navItem}>
							<YogaNav fill={nav === 'yoga' ? 'white' : '#93A2BE'} />
							{/* <img src='assets/img/nav/yoga-nav.svg' alt='yoga'/> */}
							<p className={`${styles.tag}`}>Yoga</p>
						<button className={styles.navButton}>
						</button>
					</li>
				</ul>
			</section>
			{/* <nav>
				<ul>
					<li>Voetreflexologie</li>
					<li>Kruiden Therapie</li>
					<li>Massage</li>
					<li>Yoga</li>
				</ul>
			</nav> */}
			<Yoga control={nav} />
			<Kruidentherapie control={nav} />
			<Massage control={nav} />
			<Voetreflexologie control={nav} />
		</main>
	 );

}

export default Disciplines;
