const SessieGroep = () => {
	return (
		<svg width="308" height="261" viewBox="0 0 308 261" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M128.283 73.1872C136.952 67.9884 138.723 54.9826 132.24 44.1378C125.756 33.2931 113.474 28.7161 104.805 33.9149C96.1366 39.1137 94.3651 52.1195 100.848 62.9643C107.332 73.809 119.615 78.3859 128.283 73.1872Z" fill="#DC5F5F"/>
			<path d="M147.024 21.4424C143.686 15.5349 136.356 19.3303 138.464 25.593C144.803 45.5049 151.154 73.1865 147.024 99.0968C131.957 105.5 87.1012 71.7762 69.8491 66.2985C63.0835 64.1504 59.718 72.4043 66.6224 76.461C107.372 100.586 129.476 113.532 129.005 144.334C110.625 186.975 76.6604 217.249 45.6655 224.561C40.0691 225.768 41.4239 234.402 47.251 233.642C85.0271 229.601 151.889 197.552 168.022 146.739C182.789 102.577 163.143 50.4261 147.024 21.4424Z" fill="#DC5F5F"/>
			<path d="M169.717 153.538C170.84 150.522 174.113 149.145 177.124 150.267C193.473 156.568 243.267 189.489 232.871 242.579C231.141 249.473 222.191 247.227 223.232 240.16C224.274 233.092 201.498 188.815 184.285 184.496C168.794 180.608 162.205 171.807 169.717 153.538Z" fill="#DC5F5F"/>
			<path d="M239.238 97.1893C234.065 94.0866 233.007 86.3246 236.877 79.8524C240.746 73.3801 248.076 70.6486 253.25 73.7512C258.423 76.8539 259.481 84.6159 255.611 91.0882C251.742 97.5604 244.411 100.292 239.238 97.1893Z" fill="#DC5F5F"/>
			<path d="M228.057 66.3087C230.049 62.7831 234.423 65.0482 233.165 68.7859C229.382 80.6695 225.592 97.1901 228.057 112.654C237.049 116.475 263.819 96.3484 274.116 93.0793C278.153 91.7973 280.162 96.7233 276.041 99.1444C251.722 113.543 238.53 121.269 238.81 139.651C249.78 165.1 270.05 183.168 288.549 187.532C291.889 188.252 291.08 193.405 287.602 192.951C265.057 190.54 225.154 171.412 215.525 141.087C206.712 114.731 218.437 83.6065 228.057 66.3087Z" fill="#DC5F5F"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M210.084 143.191C211.881 142.521 213.835 143.343 214.505 145.143C218.897 155.824 215.212 161.082 206.369 163.473C202.326 159.977 196.83 156.23 193.081 154.422C199.58 148.46 206.318 144.642 210.084 143.191ZM176.612 182.461C177.877 182.987 179.241 183.747 180.704 184.562C180.954 184.701 181.207 184.842 181.463 184.984C182.448 185.529 183.518 185.827 184.53 186.11C185.109 186.271 185.67 186.428 186.185 186.622C183.792 191.391 182.354 195.403 182.566 196.84C183.188 201.058 177.846 202.398 176.814 198.283C175.704 192.617 175.743 187.335 176.612 182.461Z" fill="#DC5F5F"/>
			<path d="M49.4123 105.661C53.1616 103.412 53.9278 97.7869 51.1236 93.0963C48.3195 88.4057 43.0068 86.4261 39.2575 88.6747C35.5082 90.9232 34.742 96.5486 37.5461 101.239C40.3503 105.93 45.6629 107.909 49.4123 105.661Z" fill="#DC5F5F"/>
			<path d="M57.5241 83.2802C56.0804 80.7251 52.9103 82.3667 53.8219 85.0754C56.5636 93.6878 59.3106 105.661 57.5241 116.868C51.0075 119.637 31.6062 105.051 24.1443 102.682C21.218 101.752 19.7624 105.322 22.7487 107.077C40.3737 117.512 49.9343 123.111 49.7308 136.434C41.781 154.877 27.0904 167.971 13.6843 171.134C11.2638 171.656 11.8497 175.39 14.3701 175.061C30.7091 173.314 59.6283 159.452 66.6064 137.474C72.9933 118.373 64.496 95.8163 57.5241 83.2802Z" fill="#DC5F5F"/>
			<path d="M67.3313 140.414C67.8169 139.11 69.2326 138.514 70.5352 138.999C77.6065 141.725 99.1434 155.964 94.647 178.927C93.8986 181.909 90.0274 180.937 90.4779 177.88C90.9285 174.824 81.0772 155.673 73.6325 153.804C66.9322 152.123 64.0821 148.316 67.3313 140.414Z" fill="#DC5F5F"/>
		</svg>
	 );
}

export default SessieGroep;
