const Location = () => {
	return (
		<svg width="166" height="210" viewBox="0 0 166 210" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.500011 81.6917L0.500011 81.6914C0.491834 65.6366 5.32527 49.9395 14.3896 36.5867C23.454 23.2338 36.3422 12.825 51.4241 6.67907C66.506 0.533068 83.1028 -1.07349 99.1137 2.06305C115.125 5.19959 129.829 12.9379 141.365 24.2976L141.366 24.2985C149.047 31.8208 155.134 40.7665 159.277 50.6176C163.419 60.4687 165.534 71.03 165.5 81.6901L165.5 81.6917C165.5 97.3952 159.906 113.52 151.923 128.521C143.942 143.519 133.591 157.36 124.119 168.489C111.586 183.204 97.8319 196.871 82.9998 209.347C68.1676 196.871 54.4139 183.204 41.8806 168.489C32.4089 157.36 22.058 143.518 14.0771 128.521C6.09384 113.52 0.500011 97.3952 0.500011 81.6917ZM82.9998 46.1985C73.4357 46.1985 64.2617 49.936 57.4964 56.5913C50.7308 63.2469 46.9285 72.2756 46.9285 81.6917C46.9285 91.1077 50.7308 100.136 57.4964 106.792C64.2617 113.447 73.4357 117.185 82.9998 117.185C92.5639 117.185 101.738 113.447 108.503 106.792C115.269 100.136 119.071 91.1077 119.071 81.6917C119.071 72.2756 115.269 63.2469 108.503 56.5913C101.738 49.936 92.5639 46.1985 82.9998 46.1985Z" fill="#DC5F5F" stroke="#DC5F5F"/>
		</svg>

	 );
}

export default Location;
