import styles from '../css/pages/Introductie.module.css'

const Introductie = () => {
	return (
		<main className={styles.main}>
			<section className={`${styles.section} ${styles.section1}`}>
				<h1 className={styles.title}>Introductie</h1>
			</section>
			<div className={styles.container}>
			<section className={`${styles.section} ${styles.section2}`}>
				<div className={`${styles.block} ${styles.block1}`}>
					<p className={styles.sub}>
					We zijn allen gezonden naar dit leven, in deze wereld op aarde. Wanneer we leven in eenheid, bewust leven, verbonden met onze ziel en gehoor geven aan onze levensbestemming, zijn we één of heel, ‘whole’.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph1}`}>
					Soms lukt ons dit, soms minder, ook al eens niet. Dan nodigt ons lichaam ons uit om geheeld te worden. Elk van ons bezit, naast homeostase, waarbij het evenwicht in onze cellen, weefsels en organen wordt hersteld, een immuunstysteem en daarbovenop een zelfgenezend vermogen.
					</p>
				</div>
				<div className={`${styles.block} ${styles.block2}`}>
					<p className={styles.sub}>
					Ziekte is begin van genezing of healing,.. heel-ing. Het is een uitnodiging tot introspectie en aandacht geven. Ons lichaam geeft signalen, stimuli om onszelf terug in eenheid of evenwicht te brengen.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph2}`}>
					Heeling, introspectie vergt bewust waarnemen. Trachten te begrijpen welke gevoelens ons accuut roeren en die we soms al een tijd lang negeren. Een lichamelijke klacht kan (medisch) worden gecorrigeerd, maar als de verbetering niet is geïntegreerd in volle bewustzijn, in het hele lichaam én geest, keert het lichaam terug tot zijn eerdere staat, inclusief de klacht of een andere klacht in het verlengde van de voorgaande.
					</p>
				</div>
			</section>
			<section className={`${styles.section} ${styles.section3}`}>
				<h2 className={styles.quote}>Leven in onze wereld gaat gepaard met uitdagingen of confrontatie. Daadkrachtig gaan we de confrontatie aan en bouwen we levenservaring op. </h2>
			</section>
			<section className={`${styles.section} ${styles.section4}`}>
				<div className={`${styles.block} ${styles.block3}`}>
					<p className={styles.sub}>
						Door het doen kom je tot wetenschap. Weten is doen. Bewust doen, gericht en gefocust werken, en bij momenten met ingespannen inzet; jezelf, zoals je op dat moment bent, stukje voor stukje overtreffen.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph3}`}>
						Groei, gaat vaak gepaard met spanning, woede, angst, twijfel, onzekerheid,… Iedereen beleeft dit, ik ook. Heb je dus ergens pijn, verdring de pijn niet, maar zie het als een signaal. Geef aandacht en blijft de pijn, ga naar ‘binnen’ en vraag je af wat dit signaal voor u en uw levenspad kan betekenen.
					</p>
				</div>
				<div className={`${styles.block} ${styles.block4}`}>
					<p className={styles.sub}>
						Wanneer we onze levensweg langere tijd uit het oog verliezen of onze gevoelens onderdrukken, onze uitdaging uit de weg gaan, is de eenheid zoek, stroomt onze Ki-levensenergie niet meer en worden we ziek.
					</p>
					<p className={`${styles.paragraph} ${styles.paragraph4}`}>
						We kunnen de energie weer laten stromen door bewust aandacht te geven aan onszelf en mogen daarbij hulp inroepen van vrienden, familie,... medemensen die klankbord zijn of die je helingsproces mee ondersteunen.
					</p>
				</div>
			</section>
			</div>
		</main>
	 );
}

export default Introductie;
