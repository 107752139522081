import P5sketch from '../components/P5sketch';
import HomeSplatter from '../components/HomeSplatter';
import styles from '../css/pages/index.module.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { NavLink } from "react-router-dom";
import { useState, useRef, useEffect } from 'react';

const Home = ({ bgColor }) => {
	const logoRef = useRef();
	const tagRef = useRef();
	const navRef = useRef();
	const splatterRef = useRef();
	const tl = useRef();


	useEffect(() => {
		setTimeout(() => {
				tl.current = gsap.timeline()
				.fromTo(logoRef.current, {
					opacity: '0',
					transform: 'scale(.95)',
				},
				{
					opacity: '1',
					transform: 'scale(1)',
					duration: 1.2,
					ease: 'Power1.easeInOut',
				},0)

				.fromTo(splatterRef.current, {
					opacity: '0',
				},
				{
					opacity: '1',
					duration: 2,
					ease: 'Power1.easeInOut',
				},0)
				.fromTo(tagRef.current, {
					opacity: '0',
				},
				{
					opacity: '1',
					duration: 1,
					ease: 'Power1.easeInOut',
				},1)
				.fromTo(navRef.current, {
					opacity: '0',
				},
				{
					opacity: '1',
					duration: 1,
					ease: 'Power1.easeInOut',
				},1)
			}, 250);
	}, [])

	return (
		<>
		{/* <P5sketch /> */}
		<style>{`body {background-color:${bgColor}}`}</style>
			<header>
		</header>
		<main className={styles.main__home}>
			<div className={styles.splatter__container}>
				{/* <HomeSplatter /> */}
				<img ref={splatterRef} className={styles.home__splatter} src="assets/img/home-splatter.png" alt="splatter" />
			</div>
			<article className={styles.home__content}>
				<div className={styles.logo}>
					<div className={styles.logoWrapper}>
						<img ref={logoRef} className={styles.logo__img} src="assets/img/logo.svg" alt="Matheyo" />
						<p ref={tagRef} className={styles.logo__tag}>Therapie</p>
					</div>
				</div>
			</article>
			<article ref={navRef} className={styles.home__navigation}>
				<nav className={styles.nav__home}>
					<ul className={`${styles.home__menu} ${styles.home__itemD}`}>
						<NavLink to="/Introductie" className={`${styles.home__item} ${styles.home__item1}`}>
							<div className={styles.home__link}>
								<p className={styles.link__text}>Een <br/> Introductie </p>
								<img className={styles.link__arrow} src="./assets/img/arrow.svg" alt="arrow" />
							</div>
						</NavLink>
						<NavLink to="/Aandeslag" className={`${`${styles.home__item} ${styles.home__item2}`} ${styles.home__itemT}`}>
							<div className={styles.home__link}>
									<p className={styles.link__text}>Aan de slag</p>
									<img className={styles.link__arrow} src="./assets/img/arrow.svg" alt="arrow" />
							</div>
						</NavLink>
						<NavLink to="/Disciplines" className={`${styles.home__item} ${styles.home__item3}`}>
							<div className={styles.home__link}>
									<p className={styles.link__text}>Disciplines </p>
									<img className={styles.link__arrow} src="./assets/img/arrow.svg" alt="arrow" />
							</div>
						</NavLink>
						<NavLink to="/Praktisch" className={`${styles.home__item} ${styles.home__item4}`}>
							<div className={styles.home__link}>
									<p className={styles.link__text}>Praktisch </p>
									<img className={styles.link__arrow} src="./assets/img/arrow.svg" alt="arrow" />
							</div>
						</NavLink>
						<NavLink to="/Contact" className={`${styles.home__item} ${styles.home__item5}`}>
							<div className={styles.home__link}>
									<p className={styles.link__text}>Contact</p>
									<img className={styles.link__arrow} src="./assets/img/arrow.svg" alt="arrow" />
							</div>
						</NavLink>
					</ul>
				</nav>
			</article>

			<section className={styles.canvas} id="canvas">

			</section>
		</main>

		</>
	);
}

export default Home;
