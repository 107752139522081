const Euro = () => {
	return (
		<svg width="156" height="111" viewBox="0 0 156 111" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1.5 62.603C5.32071 70.1476 12.5776 76.7698 22.0312 81.9694C36.3438 89.8417 56.0127 94.6624 77.6618 94.6624C99.2495 94.6624 118.872 89.8944 133.176 82.0882C142.686 76.8985 149.987 70.2745 153.824 62.7132V67.4719C153.824 78.4728 145.703 88.7508 131.858 96.3655C118.066 103.951 98.9092 108.687 77.6618 108.687C56.4144 108.687 37.2574 103.951 23.4657 96.3655C9.62034 88.7508 1.5 78.4728 1.5 67.4719V62.603Z" fill="#DC5F5F" stroke="#DC5F5F" strokeWidth="3"/>
			<path d="M77.1618 0.500878V0.510002H77.6618C99.0389 0.510002 118.368 5.31989 132.334 13.0754C146.318 20.8403 154.824 31.4902 154.824 43.1454C154.824 54.8006 146.315 65.4531 132.33 73.2205C118.363 80.9785 99.0339 85.7909 77.6618 85.7909C56.2846 85.7909 36.956 80.9785 22.9894 73.2205C9.00589 65.4531 0.5 54.8007 0.5 43.1454C0.5 31.4902 9.00831 20.8378 22.9931 13.0704C36.852 5.37291 55.9882 0.575292 77.1618 0.500878ZM70.5453 47.9042H96.2592H96.7592V47.4042V41.1979V40.6979H96.2592H64.283C64.1841 40.0851 64.1841 39.4716 64.283 38.8589H96.2592H96.7592V38.3589V32.1526V31.6526H96.2592H70.5453C71.9844 30.8375 73.6464 30.1022 75.5085 29.4774C79.6237 28.0964 84.4387 27.3492 89.3858 27.3467H102.459H102.959V26.8467V20.6404V20.1404H102.459H89.3849C82.0332 20.1404 75.1733 21.3727 69.5014 23.5017C64.278 25.4623 59.921 28.2385 57.1841 31.6526H46.8601H46.3601V32.1526V38.3589V38.8589H46.8601H54.1475C54.0978 39.4715 54.0978 40.0853 54.1475 40.6979H46.8601H46.3601V41.1979V47.4042V47.9042H46.8601H57.1841C59.9209 51.3183 64.2779 54.0945 69.5015 56.0551C75.1735 58.184 82.0337 59.4163 89.3859 59.4163H102.46H102.96V58.9163V52.71V52.21H102.46H89.3851C84.438 52.2076 79.6237 51.4603 75.5085 50.0794C73.6464 49.4545 71.9844 48.7192 70.5453 47.9042Z" fill="#DC5F5F" stroke="#DC5F5F"/>
		</svg>
	 );
}

export default Euro;
