import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from"react-router-dom";
import { gsap } from 'gsap';
import sound from '../audio/music_dave_miles_shades_of_orange.mp3';
import styles from '../css/components/Navigation.module.css';


const Navigation = ({ color }) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [soundStatus, setSoundStatus] = useState(false);
	const path = useLocation().pathname;
	let vol = 0.2;

	// let audio = new Audio(sound);
	// audio.muted = true;

	const handleMenu = (e) => {
		if(!openMenu) {
			setOpenMenu(true);
		} else {
			setOpenMenu(false);
		}
	}

	const handleCloseMenu = () => {
		setOpenMenu(false);
	}


	const audioRef = useRef();

	const handlePlay = () => {
		audioRef.current.play();
		// gsap.to(audioRef.current, 1, {volume:0.2});
		audioRef.current.volume = vol;
		setSoundStatus(true);
	}

	const handlePause = () => {
		audioRef.current.pause();
		// gsap.to(audioRef.current, 1, {volume:0});
		audioRef.current.volume = 0;
		audioRef.current.currentTime = 0;
		setSoundStatus(false);
	}


	return (
		<nav className={!openMenu ? styles.navigation : `${styles.navigation} ${styles.navigationHamburger}`}>
			<audio
		 src={sound}
		 ref={audioRef}
		 />
			<ul style={path === '/' ? {'justifyContent': 'flex-end'} : null}  className={styles.nav__menu}>
		  {
			path !== '/' ?
				<li className={styles.logoContainer}><NavLink style={color === '#ffffff' ? {color: 'white'} : null} to='/'>
					<svg className={styles.logo} width="36" height="47" viewBox="0 0 36 47" fill={!openMenu ? color : 'white'} xmlns="http://www.w3.org/2000/svg">
					<path d="M15.8695 44.9354V44.821V24.1265C15.9244 20.5249 13.2337 17.5522 9.7743 17.4951C6.31486 17.4379 3.45944 20.2391 3.40453 23.8406V24.1265V44.821C3.40453 45.7929 2.69068 46.5932 1.75718 46.6504C0.823677 46.6504 0.0549118 45.9072 0 44.9354V44.821V10.0062C0 4.46095 4.33803 -0.0552596 9.66448 0.00190762C12.7945 0.0590749 15.7597 1.65976 17.5718 4.34662C20.5919 -0.22676 26.5773 -1.37011 30.9703 1.77409C33.606 3.60344 35.1436 6.69048 35.1436 10.0062V44.821C35.1436 45.7929 34.3748 46.5932 33.4413 46.5932C32.5078 46.5932 31.739 45.7929 31.739 44.821V24.1265C31.7939 20.5249 29.1033 17.5522 25.6438 17.4951C22.1844 17.4379 19.329 20.2391 19.274 23.8406V24.1265V44.821C19.274 45.7929 18.5602 46.5932 17.6267 46.6504C16.6932 46.6504 15.9244 45.9072 15.8695 44.9354Z" fill=""/>
					</svg>
					</NavLink>
				</li>
				:
				null
				}

					<li
					className={
						path === '/Disciplines' || path === '/Introductie' || path === '/Aandeslag' || path === '/Praktisch' ?
						!openMenu ? `${styles.nav__items} ${styles.nav__itemsIndent}` : `${styles.nav__items} ${styles.nav__itemsIndent} ${styles.nav__itemsHamburger}`
						:
						!openMenu ? `${styles.nav__items}` : `${styles.nav__items} ${styles.nav__itemsHamburger}`
					}
					>

					{
						path !== '/' ?
						<div className={styles.items}>
							{
										path === '/Disciplines' ?
										<>
											<NavLink onClick={handleCloseMenu} className={({ isActive }) => !isActive ? `${styles.item} ${styles.bold__item} ${styles.item__red}` : `${styles.item} ${styles.bold__item} ${styles.active} ${styles.item__red}`} to='/Praktisch'>Praktisch</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? styles.item : `${styles.item} ${styles.active} ${styles.activeDark}`} to='/Introductie'>Introductie</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? styles.item : `${styles.item} ${styles.active} ${styles.activeDark}`} to='/Disciplines'>Disciplines</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? styles.item : `${styles.item} ${styles.active} ${styles.activeDark}`} to='/Aandeslag'>Aan de slag</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? `${styles.item} ${styles.bold__item}` : `${styles.item} ${styles.bold__item} ${styles.active} ${styles.activeDark}`} to='/Contact'>Contact</NavLink>
										</>
										:
										<>
											<NavLink onClick={handleCloseMenu} className={({ isActive }) => !isActive ? `${styles.item} ${styles.bold__item} ${styles.item__red}` : `${styles.item} ${styles.bold__item} ${styles.active} ${styles.activeRed} ${styles.item__red}`} to='/Praktisch'>Praktisch</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? styles.item : `${styles.item} ${styles.active}`} to='/Introductie'>Introductie</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? styles.item : `${styles.item} ${styles.active}`} to='/Disciplines'>Disciplines</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? styles.item : `${styles.item} ${styles.active}`} to='/Aandeslag'>Aan de slag</NavLink>
											<NavLink onClick={handleCloseMenu} style={color === '#ffffff' ? {color: 'white'} : null} className={({ isActive }) => !isActive ? `${styles.item} ${styles.bold__item}` : `${styles.item} ${styles.bold__item} ${styles.active}`} to='/Contact'>Contact</NavLink>
										</>
							}
						</div>
						:
						null
					}
					<div className={styles.sound}>
					<button className={styles.soundWrapper} onClick={soundStatus ? handlePause : handlePlay}>
						<div className={styles.soundContent}>
							<p className={soundStatus ? `${styles.soundTag} ${styles.soundTag1}` : `${styles.soundTag} ${styles.soundTag2}`}>Ambience</p>
							{
								path === '/' || path === '/Disciplines' ?
								<div className={soundStatus ? `${styles.soundCircle}  ${styles.soundCircleDark}` : `${styles.soundCircle}  ${styles.soundCircleDark} ${styles.soundCircle2}`}></div>
								:
								<div className={soundStatus ? `${styles.soundCircle}` : `${styles.soundCircle} ${styles.soundCircle2}`}></div>
							}
									{
										path === '/' || path === '/Disciplines' ?
										<>
										<svg className={soundStatus ? `${styles.soundIcon} ${styles.soundIconVisible}`: `${styles.soundIcon}` } width="24" height="18" viewBox="0 0 24 18" fill='none' xmlns="http://www.w3.org/2000/svg">
											<path stroke='white' d="M17 11.5241L19.7778 8.26203M22.5556 5L19.7778 8.26203M19.7778 8.26203L17 5M19.7778 8.26203L22.5556 11.5241" strokeLinecap="round" strokeLinejoin="round"/>
											<path fill='white' d="M0 11.3269V5.7555C0 5.38609 0.131696 5.03181 0.366116 4.7706C0.600537 4.50939 0.918479 4.36264 1.25 4.36264H4.62125C4.86817 4.3627 5.10957 4.28128 5.315 4.12864L10.5562 0.234211C10.7447 0.0941259 10.9637 0.0137272 11.19 0.00160706C11.4162 -0.0105131 11.6412 0.0461014 11.8408 0.165399C12.0404 0.284697 12.2072 0.462193 12.3233 0.678918C12.4394 0.895643 12.5005 1.14345 12.5 1.39585V15.6866C12.5 15.9387 12.4386 16.1862 12.3223 16.4025C12.2061 16.6188 12.0392 16.7959 11.8397 16.9149C11.6402 17.0339 11.4154 17.0902 11.1894 17.078C10.9633 17.0658 10.7445 16.9854 10.5562 16.8454L5.315 12.9524C5.10943 12.8002 4.86804 12.7193 4.62125 12.7198H1.25C0.918479 12.7198 0.600537 12.573 0.366116 12.3118C0.131696 12.0506 0 11.6963 0 11.3269V11.3269Z"/>
										</svg>
										<svg className={!soundStatus ? `${styles.soundIcon} ${styles.soundIcon2} ${styles.soundIconVisible} `: `${styles.soundIcon} ${styles.soundIcon2}` } width="26" height="19" viewBox="0 0 26 19" fill='none' xmlns="http://www.w3.org/2000/svg">
											<path stroke='white' d="M18 4.73856C18 4.73856 19.9091 6.55999 19.9091 9.5957C19.9091 12.6314 18 14.4528 18 14.4528M21.8182 1.0957C21.8182 1.0957 25 4.13142 25 9.5957C25 15.06 21.8182 18.0957 21.8182 18.0957" strokeLinecap="round" strokeLinejoin="round"/>
											<path fill='white' d="M0 12.3269V6.7555C0 6.38609 0.131696 6.03181 0.366116 5.7706C0.600537 5.50939 0.918479 5.36264 1.25 5.36264H4.62125C4.86817 5.3627 5.10957 5.28128 5.315 5.12864L10.5562 1.23421C10.7447 1.09413 10.9637 1.01373 11.19 1.00161C11.4162 0.989487 11.6412 1.0461 11.8408 1.1654C12.0404 1.2847 12.2072 1.46219 12.3233 1.67892C12.4394 1.89564 12.5005 2.14345 12.5 2.39585V16.6866C12.5 16.9387 12.4386 17.1862 12.3223 17.4025C12.2061 17.6188 12.0392 17.7959 11.8397 17.9149C11.6402 18.0339 11.4154 18.0902 11.1894 18.078C10.9633 18.0658 10.7445 17.9854 10.5562 17.8454L5.315 13.9524C5.10943 13.8002 4.86804 13.7193 4.62125 13.7198H1.25C0.918479 13.7198 0.600537 13.573 0.366116 13.3118C0.131696 13.0506 0 12.6963 0 12.3269V12.3269Z" />
										</svg>
										</>
										:
										<>
										<svg className={soundStatus ? `${styles.soundIcon} ${styles.soundIconVisible}`: `${styles.soundIcon}` } width="24" height="18" viewBox="0 0 24 18" fill='none' xmlns="http://www.w3.org/2000/svg">
											<path stroke='black' d="M17 11.5241L19.7778 8.26203M22.5556 5L19.7778 8.26203M19.7778 8.26203L17 5M19.7778 8.26203L22.5556 11.5241" strokeLinecap="round" strokeLinejoin="round"/>
											<path fill='black' d="M0 11.3269V5.7555C0 5.38609 0.131696 5.03181 0.366116 4.7706C0.600537 4.50939 0.918479 4.36264 1.25 4.36264H4.62125C4.86817 4.3627 5.10957 4.28128 5.315 4.12864L10.5562 0.234211C10.7447 0.0941259 10.9637 0.0137272 11.19 0.00160706C11.4162 -0.0105131 11.6412 0.0461014 11.8408 0.165399C12.0404 0.284697 12.2072 0.462193 12.3233 0.678918C12.4394 0.895643 12.5005 1.14345 12.5 1.39585V15.6866C12.5 15.9387 12.4386 16.1862 12.3223 16.4025C12.2061 16.6188 12.0392 16.7959 11.8397 16.9149C11.6402 17.0339 11.4154 17.0902 11.1894 17.078C10.9633 17.0658 10.7445 16.9854 10.5562 16.8454L5.315 12.9524C5.10943 12.8002 4.86804 12.7193 4.62125 12.7198H1.25C0.918479 12.7198 0.600537 12.573 0.366116 12.3118C0.131696 12.0506 0 11.6963 0 11.3269V11.3269Z"/>
										</svg>
										<svg className={!soundStatus ? `${styles.soundIcon} ${styles.soundIcon2} ${styles.soundIconVisible} `: `${styles.soundIcon} ${styles.soundIcon2}` } width="26" height="19" viewBox="0 0 26 19" fill='none' xmlns="http://www.w3.org/2000/svg">
											<path stroke='black' d="M18 4.73856C18 4.73856 19.9091 6.55999 19.9091 9.5957C19.9091 12.6314 18 14.4528 18 14.4528M21.8182 1.0957C21.8182 1.0957 25 4.13142 25 9.5957C25 15.06 21.8182 18.0957 21.8182 18.0957" strokeLinecap="round" strokeLinejoin="round"/>
											<path fill='black' d="M0 12.3269V6.7555C0 6.38609 0.131696 6.03181 0.366116 5.7706C0.600537 5.50939 0.918479 5.36264 1.25 5.36264H4.62125C4.86817 5.3627 5.10957 5.28128 5.315 5.12864L10.5562 1.23421C10.7447 1.09413 10.9637 1.01373 11.19 1.00161C11.4162 0.989487 11.6412 1.0461 11.8408 1.1654C12.0404 1.2847 12.2072 1.46219 12.3233 1.67892C12.4394 1.89564 12.5005 2.14345 12.5 2.39585V16.6866C12.5 16.9387 12.4386 17.1862 12.3223 17.4025C12.2061 17.6188 12.0392 17.7959 11.8397 17.9149C11.6402 18.0339 11.4154 18.0902 11.1894 18.078C10.9633 18.0658 10.7445 17.9854 10.5562 17.8454L5.315 13.9524C5.10943 13.8002 4.86804 13.7193 4.62125 13.7198H1.25C0.918479 13.7198 0.600537 13.573 0.366116 13.3118C0.131696 13.0506 0 12.6963 0 12.3269V12.3269Z" />
										</svg>
										</>
								}
						</div>
					</button>
					</div>
				</li>
				{
					path !== '/' ?
					<>
						<li className={styles.hamburgerContainer}>
							<button onClick={handleMenu} className={styles.hamburger}>
								<div className={openMenu ? `${styles.hamburgerClose1} ${styles.hamburgerFirst}` : `${styles.hamburgerFirst}`}></div>
								<div className={openMenu ? `${styles.hamburgerClose2} ${styles.hamburgerSecond}` : `${styles.hamburgerSecond}`}></div>
							</button>
						</li>
						<li className={!openMenu ? `${styles.background}` : `${styles.background} ${styles.backgroundActive}`}></li>
					</>
					:
					null
				}
			</ul>
		</nav>
	 );
	//  return (
	// 	<nav className={styles.navigation}>
	// 		<ul className={styles.nav__menu}>
	// 			<li><NavLink to='/'><img className={styles.home__splatter} src="assets/img/m-blue.svg" alt="splatter" /></NavLink></li>
	// 			<li className={styles.menu}>
	// 				<p>Menu</p>
	// 			</li>
	// 		</ul>
	// 	</nav>
	//  );
}

export default Navigation;
