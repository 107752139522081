import styles from '../css/pages/Praktisch.module.css';
import { useIntersection } from 'react-use';
import { useEffect, useRef, useState } from 'react';
import Location from '../components/svg/Location';
import Euro from '../components/svg/Euro';
import SessiePersoonlijk from '../components/svg/SessiePersoonlijk';
import SessieGroep from '../components/svg/SessieGroep';
import MassageNav from '../components/svg/MassageNav';
import Voetnav from '../components/svg/VoetNav';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Praktisch = () => {
	const navEl = useRef();
	const q = gsap.utils.selector(navEl);
	const tl = useRef();

	const navTransform = useRef();
	const waarPos = useRef();
	const prijsPos = useRef();
	const watPos = useRef();
	const hoePos = useRef();

	const waarNavRef = useRef();
	const watNavRef = useRef();
	const hoeNavRef = useRef();
	const prijsNavRef = useRef();

	const [navSate, setNav] = useState();

	const [activeSessie, setActiveSessie] = useState(null);

	const handleSessie = (e) => {
		if(e.currentTarget.id === 'sessiePersoonlijk') {
			setActiveSessie('persoonlijk');
		} else if (e.currentTarget.id === 'sessieGroep') {
			setActiveSessie('groep');
		}
	}

	useEffect(() => {
		window.onbeforeunload = function () {
			window.scrollTo(0, 0);
		}
		if(window.innerWidth > 600) setActiveSessie(null);

		console.log(navEl.current.children);

		const navElements = [
			waarNavRef.current,
			prijsNavRef.current,
			watNavRef.current,
			hoeNavRef.current,
		];

		// tl.current = gsap.timeline()
		// .fromTo(
		// 	navEl.current,
		// 	{width: '25rem', height: '25rem'},
		// 	{width: '15rem', height: '15rem', duration: .1,
		// 	scrollTrigger: {
		// 		// markers: true,
		// 		trigger: navTransform.current,
		// 		ease: 'none',
		// 		start: 'bottom bottom',
		// 		toggleActions: 'play none none reverse',
		// 		}
		// 	})

		// .fromTo(navElements,
		// 	{display: 'initial'},
		// 	{display: 'none',
		// 	scrollTrigger: {
		// 		trigger: navTransform.current,
		// 		start: 'bottom bottom',
		// 		toggleActions: 'play none none reverse',
		// 		}
		// 	})

		// 	.fromTo(waarNavRef.current,
		// 		{display: 'initial'},
		// 		{display: 'initial',
		// 		scrollTrigger: {
		// 			trigger: navTransform.current,
		// 			start: 'bottom bottom',
		// 			toggleActions: 'play none none reverse',
		// 			}
		// 		})

		// 		.fromTo(waarNavRef.current,
		// 			{},
		// 			{display: 'none',
		// 			scrollTrigger: {
		// 				trigger: prijsPos.current,
		// 				// start: 'bottom bottom',
		// 				toggleActions: 'play none none reverse',
		// 				}
		// 			})


		// 			.fromTo(prijsNavRef.current,
		// 				{},
		// 				{display: 'initial',
		// 				scrollTrigger: {
		// 					trigger: prijsPos.current,
		// 					// start: 'top bottom',
		// 					toggleActions: 'play none none reverse',
		// 					}
		// 				})

		// 			.fromTo(prijsNavRef.current,
		// 				{},
		// 				{display: 'none',
		// 				scrollTrigger: {
		// 					trigger: watPos.current,
		// 					toggleActions: 'play none none reverse',
		// 					}
		// 				})


		// 			.fromTo(watNavRef.current,
		// 				{},
		// 				{display: 'initial',
		// 				scrollTrigger: {
		// 					trigger: watPos.current,
		// 					toggleActions: 'play none none reverse',
		// 					}
		// 				})

		// 			.fromTo(watNavRef.current,
		// 				{},
		// 				{display: 'none',
		// 				scrollTrigger: {
		// 					trigger: hoePos.current,
		// 					toggleActions: 'play none none reverse',
		// 					}
		// 				})

		// 			.fromTo(hoeNavRef.current,
		// 				{},
		// 				{display: 'initial',
		// 				scrollTrigger: {
		// 					trigger: hoePos.current,
		// 					toggleActions: 'play none none reverse',
		// 					}
		// 				})

	}, [])

	// intersection && intersection.intersectionRatio < .5


	return (
		<>
			<header className={styles.header}>
				<h1 className={styles.title}>Praktisch</h1>
			</header>
			<main className={styles.praktisch__main}>
				{/* <div className='text' style={{gridRow:'1', gridColumn: '1'}}>hEY</div> */}
				<div ref={navTransform} className={styles.navTransformer}></div>
				<aside ref={navEl} className={`${styles.nav}`}>
					<ul className={styles.nav__menu}>
						<li ref={waarNavRef} className={`${styles.nav__item} ${styles.nav__itemActive} ${styles.nav__itemWaar}`}><a href='#waar'>Waar</a></li>
						<li ref={prijsNavRef} className={`${styles.nav__item} ${styles.nav__itemPrijs}`}><a href='#prijs'>Prijs</a></li>
						<li ref={watNavRef} className={`${styles.nav__item} ${styles.nav__itemWat}`}><a href='#wat'> Wat</a></li>
						<li ref={hoeNavRef} className={`${styles.nav__item} ${styles.nav__itemHoe}`}><a href='#hoe'>Hoe</a></li>
					</ul>
					<img className={styles.navBg} src='assets/img/nav/prak-nav-bg.svg' alt='nav-background'/>
					{/* <svg  width="375" height="125" viewBox="0 0 375 125" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M375.018 52.5C320.551 19.1946 256.517 0 188 0C119.071 0 54.6786 19.4263 0 53.1026V125H375.018V52.5Z" fill="white"/>
					</svg> */}
				</aside>
				<article className={styles.content}>
					<section id='waar' ref={waarPos} className={`${styles.section} ${styles.waar}`}>
						<div className={styles.subtitleContainer}>
						</div>
						<div className={styles.waar1}>
							<div className={styles.adresIcon}><Location /></div>
							<p className={styles.waar__adresLarge}>Waar</p>
							<h3>Waar</h3>
							<div className={styles.waar__adres}>
								<p className={styles.straat}>Wortegemseweg</p>
								<p className={styles.adresNr}>39, 8790</p>
								<p className={styles.plaats}>Waregem</p>
							</div>
						</div>
						<div className={styles.waar2}>
								<div className={`${styles.infoCopy} ${styles.infoCopy__auto}`}>
									<h4>Ben je met de Auto?</h4>
									<p className={`${styles.copyWaar} ${styles.copyAuto}`}>
										Parking voor de <b>auto</b> vind je vooraan de praktijk.<b> Kom op tijd</b>, liefst 5 minuten voor tijd, dan heb je al even de tijd om tot rust te komen.
									</p>
								</div>
								<div className={`${styles.infoCopy} ${styles.infoCopy__fiets}`}>
									<h4>Ben je met de Fiets?</h4>
									<p className={`${styles.copyWaar} ${styles.copyFiets}`}>
										Kom je met de <b>fiets</b> , dan stal je je fiets achteraan de inrijlaan (rechts van het huis)
										in het fietsrek onder de carport.
									</p>
								</div>
								<img className={`${styles.waar__img} ${styles.waar__img2}`} src='assets/img/car1.jpeg' alt='bike' />
								<img className={`${styles.waar__img} ${styles.waar__img1}`} src='assets/img/bike.jpeg' alt='car' />
						</div>
					</section>
					<section id='prijs' ref={prijsPos} className={`${styles.section} ${styles.prijs}`}>
						<h3>Prijs</h3>
						<div className={styles.prijsBg}>
							<div className={styles.prijsBg__part}></div>
							<div className={styles.prijsBg__part}></div>
						</div>
						<div className={styles.prijsContent}>
							<div className={styles.prijsTitle}>
								<p className={styles.prijsNr}>25</p>
								<p className={styles.prijsPer}>/ half uur</p>
								<div className={styles.prijsIcon}><Euro/></div>
							</div>
							<div className={activeSessie === 'persoonlijk' ? `${styles.sessie} ${styles.sessiePersoonlijk} ${styles.persoonlijkActive}` : `${styles.sessie} ${styles.sessiePersoonlijk}`}>
								<button onClick={handleSessie} id='sessiePersoonlijk' className={activeSessie === 'persoonlijk' ? `${styles.sessieMeer} ${styles.meerHidden}` : styles.sessieMeer}>Meer</button>
								<div className={activeSessie === 'persoonlijk' ? styles.activeIcon : styles.sessieIcon}><SessiePersoonlijk/></div>
								<div className={activeSessie === 'persoonlijk' ? `${styles.sessieSplit}  ${styles.activeSplit}` : `${styles.sessieSplit}`}></div>
								<p className={activeSessie === 'persoonlijk' ? `${styles.sessieTitle} ${styles.activeTitle}` : `${styles.sessieTitle}`}>Persoonlijke sessie</p>
								<p className={activeSessie === 'persoonlijk' ? `${styles.sessieCopy} ${styles.persoonlijkCopy} ${styles.activeCopy}` : `${styles.sessieCopy} ${styles.persoonlijkCopy}` }>Persoonlijke sessies, waar we a.d.h.v yoga, massage en voetreflexologie met jouw pijnpunten te werk gaan.</p>
							</div>
							<div className={activeSessie === 'groep' ? `${styles.sessie} ${styles.sessieGroep} ${styles.groepActive}` : `${styles.sessie} ${styles.sessieGroep}`}>
								<button onClick={handleSessie} id='sessieGroep' className={activeSessie === 'groep' ? `${styles.sessieMeer} ${styles.meerHidden}` : styles.sessieMeer}>Meer</button>
								<div className={activeSessie === 'groep' ? styles.activeIcon : styles.sessieIcon}><SessieGroep /></div>
								<div className={activeSessie === 'groep' ? `${styles.sessieSplit}  ${styles.activeSplit}` : `${styles.sessieSplit}`}></div>
								<p className={activeSessie === 'groep' ? `${styles.sessieTitle} ${styles.activeTitle}` : `${styles.sessieTitle}`}>Groepsessie</p>
								<p className={activeSessie === 'groep' ? `${styles.sessieCopy} ${styles.groepCopy} ${styles.activeCopy}` : `${styles.sessieCopy} ${styles.groepCopy}` }>Yoga 1-5 personen</p>
								<p className={activeSessie === 'groep' ? `${styles.sessieCopy} ${styles.groepCopy2} ${styles.activeCopy}` : `${styles.sessieCopy} ${styles.groepCopy2}` }>Prijs wordt gedeeld <br/> door # deelnemers</p>
							</div>
							<button className={styles.sessieBooking}><a className={styles.bookLink} href='mailto:peterpaul.vermeire@gmail.com'>sessie boeken</a></button>
						</div>
					</section>
					<section id='wat' ref={watPos} className={`${styles.section} ${styles.wat}`}>
						<h3>Wat</h3>
						<div className={`${styles.subtitleContainer} ${styles.subtitleContainer__grid}`}>
							<p className={`${styles.subtitle__info}`}>Wat breng</p>
							<p className={`${styles.subtitle__info} ${styles.subtitleWatBack}`}>je mee?</p>
						</div>
						<div className={styles.watInfo}>
							<div className={styles.wat__part}>
								<div className={styles.watTags}>
									<p>Voetreflexologie</p>
									<div className={styles.tagIcon}><Voetnav fill={'#DC5F5F'} stroke='#F2EEE9' /></div>
								</div>
								<div className={styles.wat__circle}>
									<img src='assets/img/Towel-small.png' alt='handoeken' />
								</div>
									<div className={`${styles.uitleg} ${styles.uitleg1}`}>
										<img src='assets/img/wat-stroke1.svg' alt='' />
										<p>2 Gewone handdoeken</p>
									</div>
									<div className={`${styles.uitleg} ${styles.uitleg2}`}>
										<img src='assets/img/wat-stroke2.svg' alt='' />
										<p>1 Badlaken</p>
									</div>
							</div>
							<div className={styles.wat__part}>
								<div className={styles.watTags}>
									<p>Massage</p>
									<div className={styles.tagIcon}><MassageNav fill={'#DC5F5F'} /></div>
								</div>
								<div className={styles.wat__circle}>
									<img src='assets/img/Towel-large.png' alt='handoeken' />
								</div>
								<div className={`${styles.uitleg} ${styles.uitleg3}`}>
									<img src='assets/img/wat-stroke3.svg' alt='' />
									<p>3 grote badlakens</p>
								</div>
							</div>
						</div>
						<div className={styles.watCopy}>
							<div className={`${styles.copyPart} ${styles.watCopy__part1}`}>
								<p className={styles.copyIndex}>1</p>
								<p className={`${styles.vergetenStrong} ${styles.copyStrong}`}>Ben je je handdoeken toch vergeten?</p>
								<p className={`${styles.vergeten} ${styles.copy}`}>
									Geen nood, je hoeft geen ommekeer te maken.
									Je kan er ook hier gebruiken; Het gebruik, wassen en drogen van een set kost <b>10 EUR</b>.
								</p>
							</div>
							<div className={`${styles.copyPart} ${styles.watCopy__part2}`}>
								<p className={styles.copyIndex}>2</p>
								<p className={`${styles.douchenStrong} ${styles.copyStrong}`}>Wil je een douche nemen na de sessie?</p>
								<p className={`${styles.douchen} ${styles.copy}`}>
									Geef dit op voorhand aan, dan voorzie ik extra een kwartier tijd.
									Het poetsen van de douche kost 10 EUR. Breng ook een extra handdoek mee!
								</p>
							</div>
							<div className={`${styles.copyPart} ${styles.watCopy__part3}`}>
								<p className={styles.copyIndex}>3</p>
								<p className={`${styles.copyStrong} ${styles.olieStrong}`}>
									In geval van reflexologie en massage zijn de oliëen inbegrepen.
								</p>
							</div>
							<div className={`${styles.copyPart} ${styles.watCopy__part4}`}>
								<p className={styles.copyIndex}>4</p>
								<p className={`${styles.copyStrong} ${styles.naSessieStrong}`}>
									Na de sessie kan je even nagenieten bij een lekkere kop kruidenthee. Deze is ook inbegrepen.
								</p>
								<p className={`${styles.hoeNaSessie} ${styles.copy}`}>
									Bachbloesems, kruiden en olieën worden aangeboden en aangerekend in functie van de handelsprijs en de samenstelling.
								</p>
							</div>
						</div>
					</section>
					<section id='hoe' ref={hoePos} className={`${styles.section} ${styles.hoe}`}>
						<h3>Hoe</h3>
						<div className={`${styles.subtitleContainer} ${styles.subtitleContainer__grid}`}>
							<p className={`${styles.subtitle__info}`}>Fris je op</p>
							<img className={styles.subtitleHoe__icon} src='assets/img/droplet.svg' alt='waterdrop - red' />
						</div>
						<div className={styles.hoeCopy}>
							<div className={`${styles.copyPart} ${styles.hoeCopy__part1}`}>
								<p className={styles.copyPart}>1</p>
								<p className={`${styles.copyStrong} ${styles.hoeReflexStrong}`}>
									Wens je een voetmassage, was je voeten voor je naar de praktijk komt.
								</p>
								<p className={`${styles.copy} ${styles.hoeReflex}`}>
									Dan kunnen we meteen aan de slag en <b>vermijden</b> we gebruik van weinig ecologische verfrissende doekjes.
								</p>
							</div>
							<div className={`${styles.copyPart} ${styles.hoeCopy__part2}`}>
								<p className={styles.copyPart}>2</p>
								<p className={`${styles.copyStrong} ${styles.hoeMassageStrong}`}>
									Wens je een lichaamsmassage, fris je op voor de afspraak.
								</p>
								<p className={`${styles.copy} ${styles.hoeMassage}`}>
									Lukt dat om praktische redenen niet, dan kan je <b>hier</b> ook een douche nemen. Deze kost 10 euro.
									Breng in dat geval nog een <b>extra</b> handdoek mee; je wil niet gemasseerd worden op een natte handdoek.
								</p>
							</div>
						</div>
					</section>
				</article>
			</main>
		</>
	);
}

export default Praktisch;
